import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { createSignalRContext } from 'react-signalr';
import { Context } from 'react-signalr/lib/signalr/types';
import { CommentHub } from '../../hubs/CommentHub';
import { FCWithChildren } from '../../types/FCWithChildren';
import { HubConnectionState } from '@microsoft/signalr';

type CommentHubContextType = { connected?: boolean } & Context<CommentHub>;

export const SignalRCommentContext = createContext<CommentHubContextType>(null!);

export const useCommentsHub = (): CommentHubContextType => useContext(SignalRCommentContext);

export const SignalRCommentProvider: FCWithChildren<{ clientFormId: string }> = (props) => {
  const { children, clientFormId } = props;

  const [connected, setConnected] = useState(false);

  const context = useMemo(
    () =>
      createSignalRContext<CommentHub>({
        shareConnectionBetweenTab: false,
      }),
    [],
  );

  const [shouldConnect, setShouldConnect] = useState(false);

  useEffect(() => {
    setShouldConnect(true);
  }, []);

  return (
    <SignalRCommentContext.Provider value={{ ...context, connected }}>
      <context.Provider
        key={clientFormId}
        url={`${import.meta.env.VITE_API_BASE_URL}/hubs/comments/${clientFormId}`}
        dependencies={[clientFormId]}
        onOpen={(connection) => setConnected(connection.state === HubConnectionState.Connected)}
        onClosed={() => setConnected(false)}
        connectEnabled={shouldConnect}
      >
        {children}
      </context.Provider>
    </SignalRCommentContext.Provider>
  );
};
