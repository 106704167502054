import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { CommentFilter, CommentRequest, CommentResponse } from '../../models/Comment';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import CommentService from '../../services/CommentService';
import CommentEditor from './CommentEditor';
import CommentList from './CommentList';
import { currentActivityCommentsStats, hidePrivateCommentsAtom } from '../../recoil/atoms/Forms';
import { EventSystem } from '../../events/EventSystem';
import CommentEvent from '../../events/QuestionCommentEvent';
import { useCommentsHub } from '../../contexts/signalR/CommentContext';
import { CommentCallbacksNames } from '../../hubs/CommentHub';
import PageLoader from '../shared/page-loader/PageLoader';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import FilterTag, { FilterSelectMode } from '../shared/tags/FilterTag';
import { Option } from '../Option';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import { groupedRecord } from '../../utils/ListUtils';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import { NIL as empty_uuid } from 'uuid';

type CommentsProps = {
  clientFormId: string;
  disabled?: boolean;
  formStepId: string;
};

const Comments: FC<CommentsProps> = (props) => {
  const { clientFormId, disabled, formStepId } = props;
  const [loading, setLoading] = useState(true);
  const setCommentStats = useSetRecoilState(currentActivityCommentsStats);
  const [mainComments, setMainComments] = useState<CommentResponse[]>([]);
  const [parentComment, setParentComment] = useState<CommentResponse | null>(null);
  const [threadComments, setThreadComments] = useState<CommentResponse[] | null>(null);
  const [editingComment, setEditingComment] = useState<CommentResponse | null>(null);
  const client = useRecoilValue(currentClientAtom);
  const toasts = useToasts();
  const toastsRef = useRef(toasts);
  const currentUser = useRecoilValue(currentUserAtom);
  const { t } = useTranslation('comments');
  const { useSignalREffect } = useCommentsHub();
  const [hidePrivateComments, setHidePrivateComments] = useRecoilState(hidePrivateCommentsAtom);
  const [filter, setFilter] = useState<Option<string, boolean>>({ id: CommentFilter.All, text: t(`filters.${CommentFilter.All}`), value: true });

  const filterOptions = useMemo<Option<string, boolean>[]>(() => {
    return [
      {
        id: CommentFilter.All,
        text: t(`filters.${CommentFilter.All}`),
        value: filter.id === CommentFilter.All,
      },
      {
        id: CommentFilter.Resolved,
        text: t(`filters.${CommentFilter.Resolved}`),
        value: filter.id === CommentFilter.Resolved,
      },
      {
        id: CommentFilter.Unresolved,
        text: t(`filters.${CommentFilter.Unresolved}`),
        value: filter.id === CommentFilter.Unresolved,
      },
      {
        id: CommentFilter.Tagged,
        text: t(`filters.${CommentFilter.Tagged}`),
        value: filter.id === CommentFilter.Tagged,
      },
      {
        id: CommentFilter.CreatedByMe,
        text: t(`filters.${CommentFilter.CreatedByMe}`),
        value: filter.id === CommentFilter.CreatedByMe,
      },
    ];
  }, [filter.id, t]);

  const filterParams = useMemo(() => {
    switch (filter.id) {
      default:
      case CommentFilter.All:
        return {};
      case CommentFilter.CreatedByMe:
        return { userId: currentUser?.id, isAuthor: true };
      case CommentFilter.Resolved:
        return { isResolved: true };
      case CommentFilter.Unresolved:
        return { isResolved: false };
      case CommentFilter.Tagged:
        return { userId: currentUser?.id, isAuthor: false };
    }
  }, [currentUser?.id, filter]);

  const refreshComments = useCallback(() => {
    if (parentComment) {
      setLoading(true);
      CommentService.getComments({ ...filterParams, clientFormId, parentId: parentComment?.commentId, formSectionId: formStepId })
        .then((res) => {
          setThreadComments([parentComment, ...res.data]);
          setLoading(false);
        })
        .catch(() =>
          toastsRef.current.addToast({
            type: ToastType.WARNING,
            title: t('toasts.refresh-fail.title'),
            description: t('toasts.refresh-fail.text'),
            expiresInMs: 5000,
          }),
        );
      return;
    }
  }, [clientFormId, filterParams, formStepId, parentComment, t]);

  useEffect(() => {
    setLoading(true);
    CommentService.getComments({ ...filterParams, clientFormId, formSectionId: formStepId })
      .then((res) => setMainComments(res.data))
      .then(() => {
        setEditingComment(null);
        setLoading(false);
      })
      .catch(() =>
        toastsRef.current.addToast({
          type: ToastType.WARNING,
          title: t('toasts.refresh-fail.title'),
          description: t('toasts.refresh-fail.text'),
          expiresInMs: 5000,
        }),
      );
  }, [clientFormId, filterParams, formStepId, t]);

  useEffect(() => {
    setParentComment(null);
    setThreadComments(null);
  }, [formStepId]);

  useEffect(() => {
    const sectionCommentsCounts = groupedRecord(mainComments, 'formSection');
    const actionCommentsCounts = groupedRecord(
      mainComments.filter((x) => x.sourceId),
      'sourceId',
      'totalReplies',
      1,
    );
    const unreadComments = mainComments.reduce((count, comment) => count + (comment.isRead ? 0 : 1), 0);
    const commentStats = {
      clientFormId: clientFormId,
      unreadCommentsCount: unreadComments,
      sectionCommentsCounts: sectionCommentsCounts,
      actionCommentsCounts: actionCommentsCounts,
    };
    setCommentStats((prev) => {
      if (prev.clientFormId !== clientFormId) {
        return commentStats;
      }
      return {
        ...prev,
        clientFormId: commentStats.clientFormId,
        unreadCommentsCount: commentStats.unreadCommentsCount,
        sectionCommentsCounts: { ...prev.sectionCommentsCounts, ...commentStats.sectionCommentsCounts },
        actionCommentsCounts: { ...prev.actionCommentsCounts, ...commentStats.actionCommentsCounts },
      };
    });
  }, [clientFormId, mainComments, setCommentStats]);

  useEffect(() => {
    if (window.location.hash.startsWith('#comment-')) {
      const commentId = window.location.hash.replace('#comment-', '');
      CommentService.getCommentById(commentId)
        .then((res) => {
          history.replaceState('', document.title, window.location.pathname + window.location.search); //remove the `#comment-...` from the url
          setParentComment(res.data);
          // refreshComments();

          if (res.data.formSection) {
            EventSystem.fireEvent('open-form-section', { sectionId: res.data.formSection, sourceId: res.data.sourceId });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      refreshComments();
    }
  }, [refreshComments]);

  const getThread = useCallback((comment: CommentResponse) => {
    setParentComment(comment);
  }, []);

  const [questionCommentId, setQuestionCommentId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const handler = (event: CommentEvent) => {
      setQuestionCommentId(event.sourceId);
      if (event.sourceId != questionCommentId) {
        setParentComment(null);
      }
    };
    EventSystem.listen('question-comment-open', handler);
    EventSystem.listen('question-comment-new', handler);
    return () => {
      EventSystem.stopListening('question-comment-open', handler);
      EventSystem.stopListening('question-comment-new', handler);
    };
  }, [mainComments, questionCommentId]);

  const questionComments = useMemo(() => {
    if (questionCommentId) {
      return mainComments.filter((x) => x.sourceId === questionCommentId);
    }
    return [];
  }, [mainComments, questionCommentId]);

  const goOutOfThread = useCallback(() => {
    setParentComment(null);
    setThreadComments(null);
    if (!questionCommentId) {
      EventSystem.fireEvent('comment-cancel', null);
    }
  }, [questionCommentId]);

  const closeQuestionComments = useCallback(() => {
    goOutOfThread();
    EventSystem.fireEvent('question-comment-open', { sourceId: '', sectionId: formStepId }); // set the selected step again to highlight
    EventSystem.fireEvent('comment-cancel', null);
  }, [formStepId, goOutOfThread]);

  useEffect(() => {
    const handler = () => {
      setQuestionCommentId(undefined);
      setParentComment(null);
      setThreadComments(null);
    };
    EventSystem.listen('question-comment-new', handler);
    return () => {
      EventSystem.stopListening('question-comment-new', handler);
    };
  }, []);

  async function editComment(comment: CommentResponse) {
    setEditingComment(comment);
  }

  async function resolveTask(comment: CommentResponse) {
    if (comment.commentId) {
      await CommentService.resolveCommentTask(comment.commentId).catch(() => {
        toasts.addToast({
          type: ToastType.ERROR,
          title: t('toasts.failure.title'),
          description: t('toasts.failure.resolve'),
          expiresInMs: 5000,
        });
      });
      if (filter.id === CommentFilter.Unresolved) {
        setMainComments((prev) => prev.filter((x) => x.commentId !== comment.commentId));
      }
    }
  }

  async function deleteComment(comment: CommentResponse) {
    if (comment.commentId) {
      await CommentService.removeComment(comment.commentId).catch(() => {
        toasts.addToast({
          type: ToastType.ERROR,
          title: t('toasts.failure.title'),
          description: t('toasts.failure.delete'),
          expiresInMs: 5000,
        });
      });
    }
  }

  async function handleCommentSave(comment: CommentRequest) {
    try {
      if (editingComment?.commentId) {
        return await CommentService.updateComment(editingComment.commentId, comment)
          .then(async () => {
            return Promise.resolve();
          })
          .catch(() => {
            toasts.addToast({
              type: ToastType.ERROR,
              title: t('toasts.failure.title'),
              description: t('toasts.failure.update'),
              expiresInMs: 5000,
            });
          })
          .finally(() => setEditingComment(null));
      }

      await CommentService.addComment({ ...comment, parentId: parentComment?.commentId || undefined })
        .then(async () => {
          return Promise.resolve();
        })
        .catch(() => {
          toasts.addToast({
            type: ToastType.ERROR,
            title: t('toasts.failure.title'),
            description: t('toasts.failure.save'),
            expiresInMs: 5000,
          });
        });
    } catch (err) {
      return Promise.reject();
    }
  }

  const onVisibilityChanged = useCallback(
    (comment: CommentResponse, isPrivate: boolean) => {
      CommentService.updateComment(comment.commentId, {
        ...comment,
        formSectionId: formStepId,
        isPrivate,
        clientFormId,
        clientId: client?.id || '',
        users: comment.users.map((user) => ({ userId: user.id || '', assignTask: user.taskAssigned })),
      }).catch(() => {
        toastsRef.current.addToast({
          type: ToastType.ERROR,
          title: t('toasts.failure.title'),
          description: t('toasts.failure.update'),
          expiresInMs: 5000,
        });
      });
    },
    [client?.id, clientFormId, formStepId, t],
  );

  const commentsUpdated = useCallback(
    (comment: CommentResponse) => {
      if (comment.formSection !== null && comment.formSection !== empty_uuid && comment.formSection !== formStepId) {
        return;
      }
      const isAuthor = comment?.author?.id === currentUser?.id;
      const forAuthorOnly = comment.isPrivate && isAuthor;
      if (isAuthor) {
        comment.isRead = true;
      }
      if (comment.parentId) {
        const parent = mainComments.find((x) => x.commentId === comment.parentId);
        if (parent) {
          const isNew = !threadComments?.find((x) => x.commentId === comment.commentId);
          if (isNew) {
            setMainComments((prev) =>
              prev.map((p) => {
                if (p.commentId === parent.commentId) {
                  return { ...p, totalReplies: p.totalReplies + 1 };
                }
                return p;
              }),
            );

            setThreadComments((prev) => prev && [...prev, comment]);
            EventSystem.fireEvent('comment-thread-updated', { commentId: parent.commentId });
          } else {
            setThreadComments(
              (prev) =>
                prev &&
                prev.map((p) => {
                  if (p.commentId === comment.commentId) {
                    return { ...p, ...comment };
                  }
                  return p;
                }),
            );
          }
        }
      } else {
        const isNew = !mainComments.find((x) => x.commentId === comment.commentId);
        if (isNew) {
          if (
            (forAuthorOnly || !comment.isPrivate) &&
            (filter.id === CommentFilter.All ||
              (filter.id === CommentFilter.CreatedByMe && isAuthor) ||
              (filter.id === CommentFilter.Resolved && comment.tasksResolved) ||
              (filter.id === CommentFilter.Unresolved && !comment.tasksResolved) ||
              (filter.id === CommentFilter.Tagged && !!comment.users.find((x) => x.id === currentUser?.id)))
          ) {
            setMainComments((prev) => [...prev, comment]);
          }
        } else {
          setMainComments((prev) =>
            prev
              .map((p) => {
                if (p.commentId === comment.commentId) {
                  return { ...p, ...comment };
                }
                return p;
              })
              .filter((x) => {
                if ((x?.author?.id === currentUser?.id && x.isPrivate) || !x.isPrivate) {
                  return true;
                }
                return false;
              }),
          );
        }
      }
    },
    [currentUser?.id, filter.id, formStepId, mainComments, threadComments],
  );

  useSignalREffect(
    CommentCallbacksNames.Added,
    (comment: CommentResponse) => {
      commentsUpdated(comment);
    },
    [commentsUpdated],
  );

  useSignalREffect(
    CommentCallbacksNames.Updated,
    (comment: CommentResponse) => {
      commentsUpdated(comment);
    },
    [commentsUpdated],
  );

  useSignalREffect(
    CommentCallbacksNames.Deleted,
    (commentId: string, parentId: string) => {
      const threadComment = threadComments?.find((x) => x.commentId === commentId);
      if (threadComment) {
        setThreadComments((prev) => prev && prev.filter((x) => x.commentId !== commentId));
      } else {
        setMainComments((prev) => prev.filter((x) => x.commentId !== commentId));
      }

      if (parentId) {
        setMainComments((prev) =>
          prev.map((p) => {
            if (p.commentId === parentId) {
              return { ...p, totalReplies: p.totalReplies - 1 };
            }
            return p;
          }),
        );
        EventSystem.fireEvent('comment-thread-updated', { commentId: parentId });
      }
    },
    [threadComments],
  );

  useSignalREffect(
    CommentCallbacksNames.Resolved,
    (comment: CommentResponse) => {
      commentsUpdated(comment);
    },
    [commentsUpdated],
  );

  const updateIsRead = useCallback(
    (comment: CommentResponse) => {
      // NOTE: only updating client side here - comments are marked as read when they are visible on screen
      // but we keep the unread dot there. This method is so that the user can clear the dot if they so want to
      const newComment: CommentResponse = { ...comment, isRead: true };
      setMainComments((prev) => prev.map((x) => (x.commentId === newComment.commentId ? newComment : x)));
      setThreadComments((prev) => prev && prev.map((x) => (x.commentId === newComment.commentId ? newComment : x)));
    },
    [setMainComments],
  );

  const markUnread = useCallback(
    (comment: CommentResponse) => {
      CommentService.setReadStatus(comment.commentId, false);

      const newComment: CommentResponse = { ...comment, isRead: false };
      setMainComments((prev) => prev.map((x) => (x.commentId === newComment.commentId ? newComment : x)));
      setThreadComments((prev) => prev && prev.map((x) => (x.commentId === newComment.commentId ? newComment : x)));
    },
    [setMainComments],
  );

  const shownComments = useMemo(
    () =>
      (parentComment ? (threadComments ?? []) : questionCommentId ? questionComments : mainComments).filter(
        (x) => !hidePrivateComments || !x.isPrivate,
      ),
    [hidePrivateComments, mainComments, parentComment, questionCommentId, questionComments, threadComments],
  );

  return (
    <div className="relative flex h-full flex-col pl-3" data-cy="comments">
      <div className="flex items-center justify-between p-2">
        <div>
          {questionCommentId && (
            <div className="flex cursor-pointer items-center font-medium text-black hover:underline" onClick={closeQuestionComments}>
              <ChevronIcon type={ChevronType.LEFT} className="h-6 w-6" />
              <span>{t('buttons.back')}</span>
            </div>
          )}
        </div>

        <div className="flex items-center justify-end gap-1">
          <FilterTag
            options={filterOptions}
            mode={FilterSelectMode.SingleDefault}
            onFiltersChange={(filters: Option<string, boolean>[]) => {
              setFilter(filters.find((x) => x.value === true) || filterOptions[0]);
            }}
          >
            <FilterTag.Slot name="Footer">
              <div className="flex items-center justify-between">
                <span>{t('show-private-comments')}</span>
                <Checkbox slider value={!hidePrivateComments} sliderSize={SliderSize.S} onChange={() => setHidePrivateComments((prev) => !prev)} />
              </div>
            </FilterTag.Slot>
          </FilterTag>
        </div>
      </div>

      <PageLoader loading={loading} loaderSize={16}>
        <CommentList
          inThread={!!parentComment}
          comments={shownComments}
          clientFormId={clientFormId}
          onEdit={editComment}
          onDelete={deleteComment}
          onResolveTask={resolveTask}
          onViewThread={getThread}
          onVisibilityChanged={onVisibilityChanged}
          goOutOfThread={goOutOfThread}
          updateIsRead={updateIsRead}
          markUnread={markUnread}
        />
      </PageLoader>
      {loading && <div className="flex-grow"></div>}
      {client?.id && !disabled && (
        <CommentEditor
          formStepId={formStepId}
          inThread={!!parentComment}
          editingComment={editingComment}
          clientId={client.id}
          clientFormId={clientFormId}
          handleCommentSave={handleCommentSave}
          handleCommentCancel={() => {
            setEditingComment(null);
          }}
        />
      )}
    </div>
  );
};

export default Comments;
