import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Client } from '../../models/Client';
import { ClientForm } from '../../models/ClientForm';
import ClientFormService from '../../services/ClientFormService';
import ClientService from '../../services/ClientService';
import Checkbox from '../shared/form-control/Checkbox';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';

type FormShareModalProps = { clientForm: ClientForm; open: boolean; onClose: () => void };

const FormShareModal: FC<FormShareModalProps> = (props) => {
  const { clientForm, open, onClose } = props;
  const { t } = useTranslation('form');
  const [affliliates, setAffiliates] = useState<Client[]>([]);
  const [originalShares, setOriginalShares] = useState<string[]>([]);
  const [shares, setShares] = useState<string[]>([]);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  useEffect(() => {
    Promise.all([ClientService.getAffiliates(clientForm.clientId), ClientFormService.getFormShares(clientForm.id)]).then((res) => {
      setAffiliates(res[0].data);
      setOriginalShares(res[1].data.map((x) => x.sharedClientId));
      setShares(res[1].data.map((x) => x.sharedClientId));
    });
  }, [clientForm.clientId, clientForm.id, open]);

  const toggleClient = (value: boolean, clientId: string) => {
    setShares((prev) => {
      if (value) {
        return [...prev, clientId];
      }
      return prev.filter((sharedClientId) => sharedClientId !== clientId);
    });
  };

  const selectAll = (value: boolean) => {
    setShares(value ? affliliates.map((client) => client.id) : []);
  };

  const save = () => {
    ClientFormService.updateFormShares(clientForm.id, shares).then(() => {
      onClose && onClose();
      setShowRevokeModal(false);
    });
  };

  const trySave = () => {
    if (originalShares.length > 0 && shares.length < originalShares.length) {
      setShowRevokeModal(true);
    } else {
      save();
    }
  };

  return (
    <>
      <ModalContext.Provider value={{ open: open, onClose: onClose, modalWidth: 'w-2/5' }}>
        <StandardModal title={t('share-modal.heading')} confirmButtonTitle={t('share-modal.save')} onCancelClick={onClose} onConfirmClick={trySave}>
          <div className="flex justify-end">
            <Checkbox
              slider
              label={t('share-modal.select-all')}
              value={affliliates.length === shares.length && shares.length > 0}
              labelBeforeCheckbox
              labelClass="text-dpm-16 font-medium"
              onChange={selectAll}
            />
          </div>
          {affliliates.map((client) => (
            <div key={client.id} className="flex justify-between">
              <div className="text-dpm-16 my-2 font-medium">{client.name}</div>
              <div>
                <Checkbox
                  slider
                  value={!!shares.find((sharedClientId) => sharedClientId === client.id)}
                  onChange={(value) => toggleClient(value, client.id)}
                />
              </div>
            </div>
          ))}
        </StandardModal>
      </ModalContext.Provider>
      <ModalContext.Provider value={{ open: showRevokeModal, onClose: () => setShowRevokeModal(false), modalWidth: 'w-2/5' }}>
        <ConfirmationModal
          title={t('share-modal.revoke.heading')}
          description={t('share-modal.revoke.body')}
          cancelText={t('share-modal.revoke.cancel')}
          confirmText={t('share-modal.revoke.continue')}
          onCancel={() => setShowRevokeModal(false)}
          onConfirm={save}
          alt
        />
      </ModalContext.Provider>
    </>
  );
};

export default FormShareModal;
