import StandardModal from '../../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import FileService from '../../../services/FileService';
import { Input } from '../../shared/form-control/Input';
import MultiTextField from '../../shared/form-control/MultiTextField';
import DocumentService from '../../../services/DocumentService';
import File from '../File';
import { useReplaceDocumentWizard } from '../../../contexts/ReplaceDocumentContext';

const DetailStep = () => {
  const { prevStep, setDocument, document, onReplaced } = useReplaceDocumentWizard();
  const { t } = useTranslation(['documents', 'organisation']);

  const fromFile = useMemo(() => !!document.fileId, [document.fileId]);

  const deleteFile = useCallback(() => {
    if (document.fileId) {
      FileService.deleteFile(document.fileId).then(() => {
        setDocument && setDocument((prev) => ({ ...prev, fileId: undefined, file: undefined }));
        prevStep && prevStep();
      });
    }
  }, [document.fileId, prevStep, setDocument]);

  const isValid = useMemo(() => {
    return document.name.length && document.changeSummary.length && (!!document.fileId || !!document.templateFormId);
  }, [document.changeSummary.length, document.fileId, document.name.length, document.templateFormId]);

  const replaceDocument = useCallback(() => {
    DocumentService.replace(document).then(() => {
      onReplaced();
    });
  }, [document, onReplaced]);

  return (
    <StandardModal
      title={t('create-doc-wizard.title')}
      cancelButtonTitle={t('manage-wizard.buttons.back')}
      onCancelClick={prevStep}
      confirmButtonTitle={t('replace-doc-wizard.buttons.replace')}
      confirmDisabled={!isValid}
      onConfirmClick={replaceDocument}
      tertiaryButtonIcon={null}
    >
      <div className="flex h-[60vh] flex-grow flex-col gap-4 overflow-auto px-1">
        <div>
          {fromFile && document.file && <File file={document.file} onRemove={deleteFile} />}
          <div className="text-dpm-16 mt-6 font-medium">{t('manage-wizard.details')}</div>
          <Input
            placeholder={t('create-doc-wizard.detail.doc-name')}
            label={t('create-doc-wizard.detail.doc-name')}
            value={document.name || ''}
            onChange={(e) => setDocument && setDocument((prev) => ({ ...prev, name: e.target.value }))}
          />
          <MultiTextField
            placeholder={t('create-doc-wizard.detail.doc-description')}
            label={t('create-doc-wizard.detail.doc-description')}
            onChange={(e) => setDocument && setDocument((prev) => ({ ...prev, description: e.target.value }))}
          />
        </div>
        <div className="mt-4">
          <MultiTextField
            placeholder={t('replace-doc-wizard.summary')}
            label={t('replace-doc-wizard.summary')}
            onChange={(e) => setDocument && setDocument((prev) => ({ ...prev, changeSummary: e.target.value }))}
            helpText={t('replace-doc-wizard.summary-description')}
          />
        </div>
      </div>
    </StandardModal>
  );
};

export default DetailStep;
