import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ClientForm } from '../../models/ClientForm';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientFormService from '../../services/ClientFormService';
import FormUtils from '../../utils/FormUtils';
import StaticBreadCrumb, { BreadCrumbItem } from '../shared/breadcumb/StaticBreadCrumb';
import LanguageUtils from '../../utils/LanguageUtils';

type DocumentBreadCrumbProps = {
  clientForm: ClientForm;
};

const DocumentBreadCrumb: FC<DocumentBreadCrumbProps> = (props) => {
  const { clientForm } = props;
  const client = useRecoilValue(currentClientAtom);
  const [formHierarchy, setFormHierarchy] = useState<BreadCrumbItem[]>([]);
  const { formId } = useParams<{ formId: string }>();
  const { t } = useTranslation(['form', 'module-list', 'common', 'documents']);

  const breadCrumbs = useMemo(() => {
    return [{ name: t('documents:heading'), path: `/clients/${client?.id}/documents/` }, ...formHierarchy];
  }, [client?.id, formHierarchy, t]);

  const createFormHieracrchy = useCallback(
    (parentId: string | null | undefined, beadCrumbItems: BreadCrumbItem[]) => {
      if (parentId) {
        ClientFormService.getFormInfo(parentId).then((res) => {
          beadCrumbItems.unshift({
            name: LanguageUtils.getTranslation('description', res.data.translations),
            path: `/clients/${client?.id}/forms/${res.data.id}`,
          });
          createFormHieracrchy(res.data.parentId, beadCrumbItems);
        });
      } else {
        setFormHierarchy(beadCrumbItems);
      }
    },
    [client?.id],
  );

  useEffect(() => {
    if (clientForm?.parentId) {
      ClientFormService.getFormInfo(clientForm?.parentId).then((res) => {
        createFormHieracrchy(res.data.parentId, [
          { name: LanguageUtils.getTranslation('description', res.data.translations), path: `/clients/${client?.id}/forms/${res.data.id}` },
        ]);
      });
    } else {
      setFormHierarchy([]);
    }
  }, [formId, clientForm?.parentId, createFormHieracrchy, client?.id]);

  return <StaticBreadCrumb breadCrumbs={breadCrumbs} currentStepName={FormUtils.getFormTitle(clientForm)} />;
};

export default DocumentBreadCrumb;
