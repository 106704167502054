import { useCallback, useState } from 'react';
import StandardModal from '../../shared/modal/variants/StandardModal';
import { FileInfo } from '../../shared/file-upload/FileInfo';
import { useTranslation } from 'react-i18next';
import FileService from '../../../services/FileService';
import { useReplaceDocumentWizard } from '../../../contexts/ReplaceDocumentContext';
import UploadFile from '../UploadFile';

const UploadStep = () => {
  const { nextStep, document, setDocument } = useReplaceDocumentWizard();
  const [fileUploadProgress, setFileUploadProgress] = useState(document.file ? 1 : 0);
  const { t } = useTranslation(['documents']);

  const deleteFile = useCallback(() => {
    setFileUploadProgress(0);
    if (document.fileId) {
      return FileService.deleteFile(document.fileId).then(() => {
        setDocument && setDocument((prev) => ({ ...prev, fileId: undefined, file: undefined }));
      });
    }
    return Promise.resolve();
  }, [document.fileId, setDocument]);

  const onUpload = useCallback(
    async (fi: FileInfo[]) => {
      if (document.file) {
        await deleteFile();
      }
      FileService.uploadFile(fi[0].file, setFileUploadProgress).then((res) => {
        setDocument && setDocument((prev) => ({ ...prev, fileId: res.data[0].id, file: fi[0] }));
      });
    },
    [deleteFile, document.file, setDocument],
  );

  return (
    <StandardModal
      title={t('create-doc-wizard.file-upload.title')}
      confirmButtonTitle={t('create-doc-wizard.buttons.continue')}
      confirmDisabled={!document.file}
      onConfirmClick={() => nextStep && nextStep('detailStep')}
      tertiaryButtonTitle={t('replace-doc-wizard.buttons.choose-template')}
      onTertiaryButtonClick={() => nextStep && nextStep('pickTemplateStep')}
      tertiaryButtonIcon={null}
    >
      <div className="flex h-[60vh] flex-grow flex-col items-center overflow-auto px-1 text-center">
        <div className="h-[55vh] w-full">
          <UploadFile file={document.file} deleteFile={deleteFile} onUpload={onUpload} fileUploadProgress={fileUploadProgress} />
        </div>
      </div>
    </StandardModal>
  );
};

export default UploadStep;
