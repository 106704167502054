import { FC, useEffect, useMemo } from 'react';
import { ClientFormSectionInfo } from '../../models/ClientForm';
import { FormConfig, FormSection, Progress } from '../../models/Form';
import FormStep, { FormStepProps } from './FormStep';
import { EventSystem } from '../../events/EventSystem';

type FormStepsRendererProps = {
  clientFormId: string;
  form: FormConfig;
  selectedStep: FormSection | undefined;
  visibleSteps: FormSection[];
  enabledStepIds: string[];
  statuses: ClientFormSectionInfo[];
  progress: Record<string, Progress>;
  onStepChange: (stepIndex: number) => void;
  currentUserPermissionChanged?: () => void;
};

const FormStepsRenderer: FC<FormStepsRendererProps> = (props) => {
  const { clientFormId, form, onStepChange, selectedStep, visibleSteps, enabledStepIds, statuses, progress, currentUserPermissionChanged } = props;

  // Case when the step you're on gets untriggered -> just go to the first step instead of displaying a blank page
  useEffect(() => {
    if (!selectedStep) {
      onStepChange(0);
    }
  }, [onStepChange, selectedStep]);

  const formSteps: FormStepProps[] = useMemo(() => {
    const steps = form.sections.map((step) => visibleSteps.find((x) => x.id === step.id)).filter((x) => x) as FormSection[];
    return (
      steps
        .map((section) => {
          const visible = !!section.visible;
          if (!visible) {
            return null;
          }

          return {
            clientFormId,
            section: section,
            selected: section.id === selectedStep?.id,
            onClick: () => {
              onStepChange(form.sections.filter((x) => !!x.visible).findIndex((x) => x.id === section.id));
              EventSystem.fireEvent('question-comment-open', { sectionId: section.id, sourceId: '' });
            },
            hasPreviousStep: false, // set in next loop,
            enabled: enabledStepIds.includes(section.id),
            info: statuses.find((x) => x.templateFormSectionId === section.id),
            progress: progress[section.id],
          };
        })
        .filter((section) => section !== null) as FormStepProps[]
    ).map((step, i) => {
      step.hasPreviousStep = i > 0;
      return step;
    });
  }, [clientFormId, enabledStepIds, form.sections, onStepChange, progress, selectedStep?.id, statuses, visibleSteps]);

  return (
    <div className="sticky top-0 box-content h-full">
      {formSteps.map((step) => (
        <FormStep key={step.section.id} {...step} currentUserPermissionChanged={currentUserPermissionChanged} />
      ))}
    </div>
  );
};

export default FormStepsRenderer;
