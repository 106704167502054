import React, { FC, useCallback, useEffect, useState } from 'react';
import Button, { ButtonSize, ButtonType } from '../../shared/form-control/Button';
import PlusIcon from '../../shared/icon/PlusIcon';
import { useTranslation } from 'react-i18next';
import SingleFileModal from '../../shared/file-upload/SingleFileModal';
import { FileInfo } from '../../shared/file-upload/FileInfo';
import { Attachment, ClientForm } from '../../../models/ClientForm';
import ContextMenu from '../../shared/ContextMenu';
import AttachmentIcon from '../../shared/icon/AttachmentIcon';
import { DocumentResponse } from '../../../models/Document';
import { FileUtils } from '../../../utils/FileUtils';
import { ModalContext } from '../../../contexts/ModalContext';
import ConfirmationModal from '../../shared/modal/variants/ConfirmationModal';
import FilePreview from '../renderer/FilePreview';
import StringUtils from '../../../utils/StringUtils';
import Tooltip from '../../shared/Tooltip';
import ClientFormService from '../../../services/ClientFormService';
import { FormType } from '../../../models/FormTypes';

type CondensedAttachmentsProps = {
  clientForm: ClientForm;
  viewOnly?: boolean;
};
const CondensedAttachments: FC<CondensedAttachmentsProps> = (props) => {
  const { clientForm, viewOnly } = props;
  const { t } = useTranslation(['form']);
  const [modalOpen, setModalOpen] = useState(false);
  const [attachmentsInternal, setAttachmentsInternal] = useState<Attachment[]>(clientForm?.attachments || []);
  const [showRemoveAttachmentModal, setShowRemoveAttachmentModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment>();

  useEffect(() => {
    setAttachmentsInternal(clientForm?.attachments ?? []);
  }, [clientForm?.attachments]);

  const saveFile = useCallback(
    (fileInfo: FileInfo) => {
      setModalOpen(false);
      ClientFormService.addAttachment(clientForm.id, fileInfo.id).then(() => {
        setAttachmentsInternal((prev) => [...prev, { fileFormat: fileInfo.mimeType, fileId: fileInfo.id, fileName: fileInfo.name }]);
      });
    },
    [clientForm],
  );

  const deleteFile = useCallback(() => {
    if (selectedAttachment) {
      ClientFormService.deleteAttachment(clientForm.id, selectedAttachment.fileId).then(() => {
        setAttachmentsInternal((prev) => prev.filter((x) => x.fileId !== selectedAttachment.fileId));
        setSelectedAttachment(undefined);
        setShowRemoveAttachmentModal(false);
      });
    }
  }, [clientForm, selectedAttachment]);

  return (
    <div className="mt-4">
      <div className="sticky top-0 z-50 flex items-center justify-between gap-2 border-b bg-white px-4 pb-2">
        <div className="font-medium">{t('left-tabs.headings.files')}</div>
        {!viewOnly && (
          <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => setModalOpen(true)}>
            <div className="flex items-center gap-2">
              <PlusIcon className="h-4 w-4" /> {t('left-tabs.references.create-new-button')}
              <SingleFileModal open={modalOpen} onSave={saveFile} onCancel={() => setModalOpen(false)} canNameFile={false} />
            </div>
          </Button>
        )}
      </div>
      {attachmentsInternal.map((attachment, i) => {
        let title = attachment.fileName;
        if (clientForm.type === FormType.Document) {
          title = `${StringUtils.makePrefixWithNumber(
            (clientForm as DocumentResponse).prefix,
            (clientForm as DocumentResponse).documentNumber,
            (clientForm as DocumentResponse).templateModuleTranslations,
          )}-${StringUtils.addLeadingZeros(i + 1)}-${title}`;
        }

        return (
          <FilePreview
            key={attachment.fileId}
            fileInfo={{
              id: attachment.fileId,
              type: attachment.fileFormat,
              name: attachment.fileName,
              file: null!,
              mimeType: attachment.fileFormat,
              size: 0,
            }}
          >
            <div className={`hover:bg-background-1 flex cursor-pointer items-center gap-2 py-3 transition-colors ${i === 0 ? '' : 'border-t'}`}>
              <div className="mx-2 flex-shrink-0">
                <AttachmentIcon className="h-5 w-5" />
              </div>
              <div className="flex-grow truncate">
                <div>
                  <Tooltip text={title} truncatedTextMode>
                    {(tooltip) => {
                      return (
                        <div {...tooltip} className="truncate font-medium underline">
                          {title}
                        </div>
                      );
                    }}
                  </Tooltip>

                  <div className="text-dpm-12">{FileUtils.getFileExtensionFromMimeType(attachment.fileFormat)}</div>
                </div>
              </div>
              <div className="flex-shrink-0">
                {!viewOnly && (
                  <ContextMenu
                    items={[
                      {
                        title: t('attachments.delete'),
                        onClick: () => {
                          setShowRemoveAttachmentModal(true);
                          setSelectedAttachment(attachment);
                        },
                        hide: !!attachment.formActionId,
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </FilePreview>
        );
      })}
      {attachmentsInternal.length === 0 && <div className="flex h-48 items-center justify-center">{t('attachments.empty')}</div>}
      <ModalContext.Provider
        value={{ open: showRemoveAttachmentModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveAttachmentModal(false), priorityOverride: 10 }}
      >
        <ConfirmationModal
          onConfirm={() => deleteFile()}
          onCancel={() => setShowRemoveAttachmentModal(false)}
          title={t('attachments.delete-confirm.title')}
          description={t('attachments.delete-confirm.description')}
          confirmText={t('attachments.delete-confirm.confirm')}
        />
      </ModalContext.Provider>
    </div>
  );
};

export default CondensedAttachments;
