import { FC, useCallback, useState } from 'react';
import { ClientForm, SignatureDetails } from '../../models/ClientForm';
import { ModalContext } from '../../contexts/ModalContext';
import useFormTypeText from '../../hooks/useFormTypeText';
import { useTranslation } from 'react-i18next';
import StandardModal from '../shared/modal/variants/StandardModal';
import MultiTextField from '../shared/form-control/MultiTextField';
import SignatureModal from './SignatureModal';

type ValidateModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (signatureDetails?: SignatureDetails) => Promise<void>;
  reason: string;
  onReasonChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  clientForm: ClientForm;
};

const ValidateModal: FC<ValidateModalProps> = (props) => {
  const { open, onClose, onConfirm, reason, onReasonChange, clientForm } = props;
  const { t } = useTranslation('common');
  const formTypeText = useFormTypeText(clientForm.type, t);
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    onConfirm().finally(() => {
      setSubmitting(false);
    });
  }, [onConfirm]);

  if (clientForm.form.requiresValidationSignature) {
    return (
      <ModalContext.Provider value={{ open: open, modalWidth: 'w-1/3', onClose: onClose }}>
        <SignatureModal mode="validate" clientForm={clientForm} reason={reason} onReasonChange={onReasonChange} onSign={onConfirm} />
      </ModalContext.Provider>
    );
  }

  return (
    <ModalContext.Provider value={{ open: open, modalWidth: 'w-1/3', onClose: onClose }}>
      <StandardModal
        title={t('form-header.modals.validate.heading', { type: formTypeText })}
        subTitle={t('form-header.modals.validate.text', { type: formTypeText })}
        cancelButtonTitle={t('form-header.modals.validate.buttons.no')}
        confirmButtonTitle={t('form-header.modals.validate.buttons.yes')}
        onCancelClick={onClose}
        onConfirmClick={() => onSubmit()}
        confirmDisabled={isSubmitting}
        confirmLoading={isSubmitting}
      >
        <MultiTextField value={reason} onChange={onReasonChange} placeholder={t('form-header.modals.validate.placeholder')} />
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default ValidateModal;
