import { CommentResponse } from '../models/Comment';

export enum CommentMethodNames {
  IsTyping = 'IsTyping',
}

export type CommentMethods = {
  [CommentMethodNames.IsTyping]: (username: string) => void;
};

export enum CommentCallbacksNames {
  Added = 'Added',
  Updated = 'Updated',
  Deleted = 'Deleted',
  Resolved = 'Resolve',
  NotifyIsTyping = 'NotifyIsTyping',
}

export type CommentCallbacks = {
  [CommentCallbacksNames.Added]: (comment: CommentResponse) => void;
  [CommentCallbacksNames.Updated]: (comment: CommentResponse) => void;
  [CommentCallbacksNames.Deleted]: (commentId: string, parentId: string) => void;
  [CommentCallbacksNames.Resolved]: (comment: CommentResponse) => void;
  [CommentCallbacksNames.NotifyIsTyping]: (username: string) => void;
};

export interface CommentHub {
  methodsName: CommentMethodNames;
  methods: CommentMethods;

  callbacksName: CommentCallbacksNames;
  callbacks: CommentCallbacks;
}
