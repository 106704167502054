import { ApiResponse } from '../models/ApiResponse';
import { CommentRequest, CommentResponse } from '../models/Comment';
import BaseService from './BaseService';

interface GetAllUserProps {
  clientFormId: string;
  formSectionId: string;
  userId?: string;
  clientId?: string;
  isAuthor?: boolean;
  parentId?: string;
}

export default class CommentService extends BaseService {
  public static getComments(props: GetAllUserProps): Promise<ApiResponse<CommentResponse[]>> {
    return this.get('/v1/comments', { params: props });
  }

  public static getCommentById(commentId: string): Promise<ApiResponse<CommentResponse>> {
    return this.get(`/v1/comments/${commentId}`);
  }

  public static updateComment(commentId: string, body: CommentRequest): Promise<ApiResponse<CommentResponse[]>> {
    return this.put(`/v1/comments/${commentId}`, body);
  }

  public static resolveCommentTask(commentId: string): Promise<ApiResponse<CommentResponse[]>> {
    return this.put(`/v1/comments/${commentId}/task/resolve`);
  }

  public static addComment(body: CommentRequest): Promise<ApiResponse<CommentResponse[]>> {
    return this.post(`/v1/comments`, body);
  }

  public static removeComment(commentId: string): Promise<ApiResponse<CommentResponse[]>> {
    return this.delete(`/v1/comments/${commentId}`);
  }

  public static setReadStatus(commentId: string, markAsRead: boolean): Promise<ApiResponse<boolean>> {
    return this.put('/v1/comments/read-status', { commentId, markAsRead });
  }
}
