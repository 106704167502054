import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Risk, RiskCalculated, RiskRatingKeys, RiskType } from '../../../models/Risk';
import RiskUtils from '../../../utils/RiskUtils';
import RiskActionIndicator from '../../risk/RiskActionIndicator';
import DoubleArrowRightIcon from '../../shared/icon/DoubleArrowRightIcon';
import EditIcon from '../../shared/icon/EditIcon';
import InfoIcon from '../../shared/icon/InfoIcon';
import Tooltip from '../../shared/Tooltip';
import { EventSystem } from '../../../events/EventSystem';
import LanguageUtils from '../../../utils/LanguageUtils';
import { nextTick } from '../../../utils/ReactUtils';
import { useTranslation } from 'react-i18next';
import { interpolateActionData } from '../../../utils/interpolation/ActionDataInterpolator';

const CondencedRiskItem: FC<{ risk: Risk; viewOnly?: boolean }> = ({ risk, viewOnly }) => {
  const { t } = useTranslation(['form', 'risk']);
  const [toHighlight, setToHighlight] = useState<string[]>([]);
  const selfRef = useRef<HTMLDivElement>(null);

  const highlightQuestion = useCallback((risk: Risk) => {
    if (!risk.actionId || !risk.sectionId) return;

    EventSystem.fireEvent('highlight-question', { sourceId: risk.actionId, sectionId: risk.sectionId, formId: risk.clientFormId, isRisk: true });
    EventSystem.fireEvent('highlight-risk', { riskIds: [risk.id] });
  }, []);

  const editRisk = useCallback(
    (risk: Risk) => {
      highlightQuestion(risk);
      EventSystem.fireEvent('question-risk-edit', { risk: risk });
    },
    [highlightQuestion],
  );

  const viewRisk = useCallback(
    (risk: Risk) => {
      highlightQuestion(risk);
      EventSystem.fireEvent('question-risk-view', { risk: risk });
    },
    [highlightQuestion],
  );

  const getActionTitle = useCallback((risk: Risk) => {
    return interpolateActionData(
      LanguageUtils.getTranslation('question', risk.actionTranslations) || LanguageUtils.getTranslation('title', risk.actionTranslations),
      risk.placeholders,
    );
  }, []);

  useEffect(() => {
    const handler = (evt: { riskIds: string[] }) => {
      setToHighlight(evt.riskIds);
    };

    EventSystem.listen('highlight-risk', handler);

    return () => {
      EventSystem.stopListening('highlight-risk', handler);
    };
  }, []);

  useEffect(() => {
    if (toHighlight.some((x) => x === risk.id)) {
      nextTick(() => selfRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' }));
    }
  }, [risk.id, toHighlight]);

  return (
    <div
      key={risk.id}
      ref={selfRef}
      className={`border-b-gray-5 flex w-full border-b pb-4 pr-2 pt-4 ${toHighlight.some((x) => x === risk.id) ? 'bg-accent-light-mid' : ''}`}
    >
      <div className="flex w-8 flex-shrink-0 justify-center px-2 text-right">
        {risk.friendlyId > 0 && risk.friendlyId}
        {risk.type === RiskType.calculated && (
          <div
            data-cy="risk-dot"
            className={`mt-2 h-2 w-2 rounded-full ${RiskUtils.bgColorFor((risk as RiskCalculated).score)}`}
            title={RiskUtils.textFor(t, (risk as RiskCalculated).score) || ''}
          />
        )}
      </div>
      <div className="flex-grow">
        <div className="text-semantic-5 flex min-w-full max-w-0 cursor-pointer items-start gap-1" onClick={() => highlightQuestion(risk)}>
          <Tooltip text={getActionTitle(risk)} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className="line-clamp-2 hover:font-medium hover:underline">
                {getActionTitle(risk)}
              </div>
            )}
          </Tooltip>
          <DoubleArrowRightIcon className="mt-[0.3rem] h-3 w-3 flex-shrink-0" />
        </div>
        <div className="mt-1 min-w-full max-w-0">
          <Tooltip text={risk.title} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className="line-clamp-2">
                {risk.title}
              </div>
            )}
          </Tooltip>
        </div>

        {risk.type !== RiskType.calculated && (
          <div className="mt-2 flex items-center gap-2">
            <Tooltip text={t(RiskRatingKeys[risk.riskRating])}>
              {(tooltip) => (
                <div {...tooltip}>
                  <RiskActionIndicator riskRating={risk.riskRating} flagOnly />
                </div>
              )}
            </Tooltip>
            <div className="bg-background-1 h-5 w-[2px]"></div>
            <Tooltip text={t('risk:tooltips.info')}>
              {(tooltip) => (
                <div {...tooltip}>
                  <InfoIcon className="bg-background-1 h-7 w-7 flex-shrink-0 p-1" onClick={() => viewRisk(risk)} />
                </div>
              )}
            </Tooltip>
            {!viewOnly && (
              <Tooltip text={t('risk:tooltips.edit')}>
                {(tooltip) => (
                  <div {...tooltip}>
                    <EditIcon className="bg-background-1 h-7 w-7 flex-shrink-0 p-1" onClick={() => editRisk(risk)} />
                  </div>
                )}
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CondencedRiskItem;
