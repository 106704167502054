import { ClientFormUser } from './ClientFormUser';

export interface CommentUser extends ClientFormUser {
  taskAssigned: boolean;
  taskResolvedUtc: Date | null;
}

export interface CommentResponse {
  commentId: string;
  parentId?: string;
  sourceId?: string;
  author?: ClientFormUser;
  text: string;
  users: CommentUser[];
  tasksAssigned: boolean;
  tasksResolved: boolean;
  editUntilUtc: Date;
  createdUtc: Date;
  totalReplies: number;
  isPrivate: boolean;
  formSection: string | null;
  isRead: boolean;
  hasUnreadReplies: boolean;
}

export interface CommentRequest {
  clientFormId: string;
  formSectionId: string;
  sourceId?: string;
  clientId: string;
  parentId?: string;
  text: string;
  isPrivate: boolean;
  users: {
    userId: string;
    assignTask: boolean;
  }[];
}

export enum CommentFilter {
  All = 'all',
  Resolved = 'resolved',
  Unresolved = 'unresolved',
  Tagged = 'tagged',
  CreatedByMe = 'created-by-me',
}
