import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProfileImageStack, ImageSize } from '../ownership/ProfileImageStack';
import ContextMenu, { ContextMenuItem } from '../shared/ContextMenu';
import { CommentResponse } from '../../models/Comment';
import CommentUtils from '../../utils/CommentUtils';
import TickIcon from '../shared/icon/TickIcon';
import Badge from '../shared/badge/Badge';
import { Trans, useTranslation } from 'react-i18next';
import ClosedEyeIcon from '../shared/icon/ClosedEyeIcon';
import Loader from '../shared/Loader';
import Linkify from 'react-linkify';
import { SecureLink } from 'react-secure-link';
import TimeAgo from '../shared/TimeAgo';
import Tooltip from '../shared/Tooltip';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import { useOnScreen } from '../../hooks/useOnScreen';
import CommentService from '../../services/CommentService';
import { EventSystem } from '../../events/EventSystem';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import Checkbox from '../shared/form-control/Checkbox';
import { useWarnOutboundLink } from '../../hooks/useWarnOutboundLink';

export interface CommentCardProps {
  comment: CommentResponse;
  isFirst?: boolean;
  isLast?: boolean;
  inThread?: boolean;
  totalComments: number;
  clientFormId: string;
  onDelete: (comment: CommentResponse) => void;
  onEdit: (comment: CommentResponse) => void;
  onResolveTask: (comment: CommentResponse) => Promise<void>;
  onViewThread: (comment: CommentResponse) => void;
  onVisibilityChanged: (comment: CommentResponse, isPrivate: boolean) => void;
  updateIsRead: () => void;
  goOutOfThread: () => void;
  markUnread: () => void;
}

export const CommentCard: FC<CommentCardProps> = ({
  comment,
  inThread,
  onEdit,
  onDelete,
  onResolveTask,
  onViewThread,
  isFirst,
  isLast,
  onVisibilityChanged,
  goOutOfThread,
  updateIsRead,
  totalComments,
  markUnread,
  clientFormId,
}) => {
  const { author, createdUtc, text, tasksAssigned, tasksResolved, users } = comment;
  const { t } = useTranslation('comments');
  const [isRead, setIsRead] = useState(comment.isRead);
  const ref = useRef<HTMLDivElement>(null);
  const currentUser = useRecoilValue(currentUserAtom);

  const editExpired = new Date(comment.editUntilUtc).getTime() < new Date().getTime();
  const currentUserIsAuthor = currentUser?.id === comment.author?.id;
  const noTasksResolved = comment.users.filter((user) => !!user.taskResolvedUtc).length === 0;
  const canChangeComment = !editExpired && currentUserIsAuthor && noTasksResolved;
  const [updatedThread, setUpdatedThread] = useState('');
  const [busyResolvingTask, setBusyResolvingTask] = useState(false);

  const visible = useOnScreen(ref);
  const externalLinkWarner = useWarnOutboundLink();

  useEffect(() => {
    if (visible && !isRead) {
      setIsRead(true);
      CommentService.setReadStatus(comment.commentId, true);
    }
  }, [comment.commentId, isRead, visible]);

  const contextMenuItems: ContextMenuItem[] = useMemo(
    () => [
      {
        title: t('context-menu.edit'),
        onClick: (): void => {
          onEdit(comment);
        },
        hide: !canChangeComment,
      },
      {
        title: t('context-menu.make-private'),
        onClick: () => onVisibilityChanged(comment, true),
        hide: ((!inThread || isFirst) && comment.isPrivate) || !canChangeComment || inThread,
      },
      {
        title: t('context-menu.make-public'),
        onClick: () => onVisibilityChanged(comment, false),
        hide: !((!inThread || isFirst) && comment.isPrivate) || !canChangeComment || inThread,
      },
      {
        title: t('context-menu.delete'),
        onClick: (): void => {
          onDelete(comment);
        },
        hide: !canChangeComment,
      },
      {
        title: t('context-menu.mark-unread'),
        onClick: () => {
          markUnread();
        },
        hide: !comment.isRead,
      },
      {
        title: t('context-menu.mark-read'),
        onClick: () => {
          updateIsRead();
        },
        hide: comment.isRead,
      },
    ],
    [canChangeComment, comment, inThread, isFirst, markUnread, onDelete, onEdit, onVisibilityChanged, t, updateIsRead],
  );

  const showContextMenu = useMemo(() => contextMenuItems.some((x) => !x.hide), [contextMenuItems]);

  /**
   * Formats the comment text mentions
   */
  const textWithMentions = () => {
    const jsx: Array<string | JSX.Element> = [];

    text.split(/[\n]/u).forEach((line, lineIndex) => {
      line.split(/[\s]/u).forEach((word, wordIndex) => {
        const taggedUser = comment.users.find(({ firstName, lastName }) => word.includes(CommentUtils.generateTag([firstName, lastName])));

        if (taggedUser) {
          const { firstName, lastName } = taggedUser;

          // e.g. @JohnDoe. becomes John Doe.
          const formattedWord = word.replace(CommentUtils.generateTag([firstName, lastName]), `${firstName} ${lastName}`);

          jsx.push(
            <span key={`${lineIndex}-${wordIndex}`} className="text-color-1 font-medium">
              {' '}
              {formattedWord}{' '}
            </span>,
          );
        } else {
          jsx.push(<span key={`${lineIndex}-${wordIndex}`}>{` ${word} `}</span>);
        }
      });

      jsx.push(<br key={`${lineIndex}-br`} />);
    });

    return <div>{jsx.map((content) => content)}</div>;
  };

  const resolveTask = () => {
    setBusyResolvingTask(true);
    onResolveTask(comment).finally(() => setTimeout(() => setBusyResolvingTask(false), 500));
  };

  const currentTaskUser = useMemo(() => users.find((x) => x.id === currentUser?.id), [currentUser?.id, users]);
  const isCurrentUserTaskResolved = useMemo(() => !!currentTaskUser?.taskResolvedUtc, [currentTaskUser?.taskResolvedUtc]);

  useEffect(() => {
    const handler = (event: { commentId: string }) => {
      setUpdatedThread(event.commentId);
    };
    EventSystem.listen('comment-thread-updated', handler);
    return () => EventSystem.stopListening('comment-thread-updated', handler);
  }, []);

  const highlightQuestion = useCallback(() => {
    if (!comment.sourceId || !comment.formSection) return;

    EventSystem.fireEvent('highlight-question', { sourceId: comment.sourceId, sectionId: comment.formSection, formId: clientFormId });
  }, [comment, clientFormId]);

  return (
    <div className="my-1 flex flex-col" data-cy="comment-card" ref={ref}>
      <div className="flex items-center gap-2 py-2 pl-1 pr-2">
        <div className="flex flex-shrink-0 items-center gap-1 self-start" data-cy="comment-author">
          {inThread && isFirst ? (
            <ChevronIcon type={ChevronType.LEFT} className="h-6 w-6" onClick={goOutOfThread} />
          ) : (
            <Tooltip text={comment.hasUnreadReplies ? t('unread-thread-badge') : comment.isRead ? t('context-menu.mark-unread') : t('unread-badge')}>
              {(tooltip) => (
                <span {...tooltip}>
                  <Checkbox
                    dot
                    value={!comment.isRead || comment.hasUnreadReplies}
                    onChange={comment.hasUnreadReplies ? undefined : (value) => (value ? markUnread() : updateIsRead())}
                  />
                </span>
              )}
            </Tooltip>
          )}
          <ProfileImageStack users={author ? [author] : []} size={ImageSize.S} />
        </div>

        <div className="w-0 flex-1">
          <div className="flex flex-grow items-center justify-center gap-4">
            <div className="flex-grow">
              <div className="text-dpm-14 font-medium text-black" data-cy="author-name">
                {author ? (author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.email) : t('removed-user')}
              </div>
              <div className="text-dpm-12 text-color-3 font-medium" data-cy="commented-at">
                <TimeAgo date={new Date(createdUtc)} />
              </div>
            </div>
            {(!inThread || isFirst) && comment.isPrivate && (
              <Tooltip text={t('thread.private-comment-hint')}>
                {(tooltip) => (
                  <span {...tooltip}>
                    <ClosedEyeIcon className="h-6 w-6" data-cy="private-comment-hint" />
                  </span>
                )}
              </Tooltip>
            )}
            {(tasksResolved || isCurrentUserTaskResolved) && (
              <Badge text={t('resolved')} textClass="text-status-tag-4" backgroundClass="bg-status-tag-4 bg-opacity-10" data-cy="comment-resolved" />
            )}
            {tasksAssigned && currentTaskUser?.taskAssigned && !isCurrentUserTaskResolved && !tasksResolved && !busyResolvingTask && (
              <TickIcon className="text-status-tag-4 mx-2 h-6 w-6 cursor-pointer" onClick={resolveTask} data-cy="resolve-comment" />
            )}
            {busyResolvingTask && (
              <div className="flex items-center justify-center">
                <Loader size={6} centered={false} />
              </div>
            )}
            {showContextMenu && <ContextMenu items={contextMenuItems} />}
          </div>

          <div className="text-dpm-14 mt-2 overflow-x-auto">
            <pre
              data-cy="comment-content"
              className="whitespace-pre-line font-sans"
              style={{
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
              }}
              onClick={highlightQuestion}
            >
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <SecureLink href={decoratedHref} key={key} className="text-link-1 font-bold underline" onClick={externalLinkWarner.onClick}>
                    {decoratedText}
                  </SecureLink>
                )}
              >
                {textWithMentions()}
              </Linkify>
            </pre>
          </div>

          {!inThread && (
            <div className="text-dpm-14 mt-4 flex text-black">
              <div data-cy="thread-count" className="flex cursor-pointer gap-1 hover:underline" onClick={() => onViewThread(comment)}>
                {comment.totalReplies === 0 ? (
                  <div data-cy="open-thread" className="cursor-pointer font-medium">
                    {t('thread.reply-to-comment')}
                  </div>
                ) : (
                  <Trans
                    t={t}
                    i18nKey="thread.comment-count"
                    values={{
                      count: comment.totalReplies,
                    }}
                    components={{
                      bold: (
                        <span
                          className={`font-medium ${updatedThread === comment.commentId ? 'animate-bounce-short' : ''} block`}
                          onAnimationEnd={() => setUpdatedThread('')}
                        />
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2">
        {inThread && isFirst && totalComments > 1 && (
          <div className="flex gap-2 pl-2">
            <Trans
              t={t}
              i18nKey="thread.comment-count"
              values={{
                count: totalComments - 1,
              }}
              components={{
                bold: (
                  <span
                    className={`font-medium ${updatedThread === comment.commentId ? 'animate-bounce-short' : ''} block`}
                    onAnimationEnd={() => setUpdatedThread('')}
                  />
                ),
              }}
            />
          </div>
        )}
        {(inThread ? isFirst : true) && <div className={`${isLast ? '' : 'border-gray-4 my-2 flex-grow border-t'}`}></div>}
      </div>
      {externalLinkWarner.modal}
    </div>
  );
};
