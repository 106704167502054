export enum ClientFormSectionStatus {
  NotSubmitted = 0,
  Submitted = 1,
  Approved = 2,
  Completed = 3,
}

export const sectionStatusKeys = {
  [ClientFormSectionStatus.NotSubmitted]: 'common:form-section-status.in-progress',
  [ClientFormSectionStatus.Submitted]: 'common:form-section-status.submitted',
  [ClientFormSectionStatus.Approved]: 'common:form-section-status.approved',
  [ClientFormSectionStatus.Completed]: 'common:form-section-status.completed',
} as const;

export const sectionStatusToasterKeys = {
  [ClientFormSectionStatus.NotSubmitted]: 'common:form-section.toasts.in-progress',
  [ClientFormSectionStatus.Submitted]: 'common:form-section.toasts.submitted',
  [ClientFormSectionStatus.Approved]: 'common:form-section.toasts.approved',
  [ClientFormSectionStatus.Completed]: 'common:form-section.toasts.completed',
} as const;

export const sectionStatusBadgeBackgrounds = {
  [ClientFormSectionStatus.NotSubmitted]: 'bg-semantic-light-2',
  [ClientFormSectionStatus.Submitted]: 'bg-semantic-light-3',
  [ClientFormSectionStatus.Approved]: 'bg-semantic-1',
  [ClientFormSectionStatus.Completed]: 'bg-semantic-light-2',
};
