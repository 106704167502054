import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { currentClientAtom } from '../../recoil/atoms/Clients';

const useOnlyHasPermission = () => {
  const currentUser = useRecoilValue(currentUserAtom);
  const currentClient = useRecoilValue(currentClientAtom);

  return useCallback(
    (permission: string) => {
      const userRoles = currentClient?.id ? currentUser?.roles[currentClient.id] || [] : currentUser?.roles[currentUser.defaultAccountId || ''] || [];

      return userRoles.length <= 1 && userRoles[0] === permission;
    },
    [currentClient?.id, currentUser?.defaultAccountId, currentUser?.roles],
  );
};

export default useOnlyHasPermission;
