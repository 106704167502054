import { CommentResponse } from '../../models/Comment';
import { CommentCard } from './CommentCard';
import Button, { ButtonType } from '../shared/form-control/Button';
import { ArrowIcon, ArrowType } from '../shared/icon/ArrowIcon';
import { useMemo, useRef } from 'react';
import useIntersection from '../../hooks/useIntersection';
import RootPortal from '../shared/RootPortal';
import { useTranslation } from 'react-i18next';

interface CommentListProps {
  comments: CommentResponse[];
  inThread?: boolean;
  clientFormId: string;
  onEdit: (comment: CommentResponse) => void;
  onDelete: (comment: CommentResponse) => void;
  onResolveTask: (comment: CommentResponse) => Promise<void>;
  onViewThread: (comment: CommentResponse) => void;
  onVisibilityChanged: (comment: CommentResponse, isPrivate: boolean) => void;
  goOutOfThread: () => void;
  updateIsRead: (comment: CommentResponse) => void;
  markUnread: (comment: CommentResponse) => void;
}

const CommentList = ({
  comments,
  onEdit,
  onDelete,
  onResolveTask,
  onViewThread,
  inThread,
  onVisibilityChanged,
  goOutOfThread,
  updateIsRead,
  markUnread,
  clientFormId,
}: CommentListProps): JSX.Element => {
  const lastElementRef = useRef<HTMLDivElement>(null);
  const entry = useIntersection(lastElementRef, {});

  const scrollInToView = () => {
    lastElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };

  const { t } = useTranslation('comments');

  const isMoreButtonVisible = useMemo(() => {
    if (comments.length <= 1) {
      return false;
    }
    return !comments[comments.length - 1]?.isRead && !entry?.isIntersecting;
  }, [comments, entry]);

  return (
    <div className="h-0 flex-auto overflow-y-auto pr-3" data-cy="comment-list">
      {comments.length === 0 && <div className="flex h-full w-full items-center justify-center">{t('empty-message')}</div>}

      {comments.map((comment, index) => {
        const isLast = comments.length === index + 1;
        return (
          <div key={comment.commentId} ref={isLast ? lastElementRef : undefined}>
            <CommentCard
              clientFormId={clientFormId}
              goOutOfThread={goOutOfThread}
              inThread={inThread}
              isFirst={index === 0}
              isLast={isLast}
              totalComments={comments.length}
              onEdit={onEdit}
              onDelete={onDelete}
              onResolveTask={onResolveTask}
              comment={comment}
              onViewThread={onViewThread}
              onVisibilityChanged={onVisibilityChanged}
              updateIsRead={() => updateIsRead(comment)}
              markUnread={() => markUnread(comment)}
            />
          </div>
        );
      })}
      {isMoreButtonVisible && (
        <RootPortal elementId="more-comments-portal">
          <div className="absolute -top-20 left-1/2 -translate-x-1/2">
            <Button type={ButtonType.PRIMARY} onClick={scrollInToView}>
              <>
                <ArrowIcon type={ArrowType.DOWN} className="h-4 w-4" /> {inThread ? t('unread-thread-badge') : t('unread-button')}
              </>
            </Button>
          </div>
        </RootPortal>
      )}
    </div>
  );
};

export default CommentList;
