import { useState, useEffect } from 'react';
import { EventSystem } from '../events/EventSystem';
import ClientFormVersionHistory, { PerformedAction } from '../models/ClientFormVersionHistory';

const useHistoriesForPerformingAction = (formId: string | undefined) => {
  const [userHistories, setUserHistories] = useState<ClientFormVersionHistory[]>([]);

  useEffect(() => {
    const handler = (evt: { clientFormId: string; versions: ClientFormVersionHistory[] }) => {
      if (evt.clientFormId !== formId) return;
      const versions = evt.versions.filter((x) => typeof x.acceptedStatus === 'boolean');
      const endIndex = versions.findIndex(
        (x) =>
          x.performedAction === PerformedAction.ClientFormReject ||
          x.performedAction === PerformedAction.ClientFormReopened ||
          x.performedAction === PerformedAction.ClientFormReviewStarted,
      );
      setUserHistories(endIndex !== -1 ? versions.slice(0, endIndex) : versions);
    };

    EventSystem.listen('user-state-histories-loaded', handler);
    return () => {
      EventSystem.stopListening('user-state-histories-loaded', handler);
    };
  }, [formId]);

  return userHistories;
};

export default useHistoriesForPerformingAction;
