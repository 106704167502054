import { MutableRefObject } from 'react';
import { ClientFormUser } from '../../../models/ClientFormUser';
import { Option } from '../../Option';
import CommentListRenderer from '../list-renderers/CommentListRenderer';
import { SelectListMenu } from '../SelectListMenu';

const Mention = (props: {
  formUsers: ClientFormUser[];
  selectedIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textBox: MutableRefObject<any>;
  onUserSelected: (user: ClientFormUser) => void;
}): JSX.Element => {
  const { onUserSelected, formUsers, selectedIndex, textBox } = props;

  const userOptions = formUsers.map((user) => {
    return {
      id: user.id || '',
      value: `${user.fullName}`,
      text: `${user.fullName}`,
    };
  });

  return (
    <SelectListMenu
      style={{ height: 'fit-content' }}
      data-cy="mention-select-list"
      className="mention"
      options={userOptions}
      isOpen
      onClick={(user: Option<string, string | number>) => {
        onUserSelected(formUsers.find((x) => x.id === user.id)!);
      }}
      customListItemRenderer={CommentListRenderer(formUsers)}
      selectedIndex={selectedIndex}
      containingRef={textBox}
      width="w-fit"
    />
  );
};

export default Mention;
