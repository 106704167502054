import { createContext, useContext } from 'react';
import { DocumentReplaceRequest } from '../models/Document';
import { ReplaceDocumentWizardStep } from '../components/documents/replace-document/ReplaceDocumentWizard';

type ReplaceDocumentContext = {
  document: DocumentReplaceRequest;
  setDocument?: (value: DocumentReplaceRequest | ((prev: DocumentReplaceRequest) => DocumentReplaceRequest)) => void;
  cancel?: () => void;
  nextStep?: (stepName: ReplaceDocumentWizardStep) => void;
  prevStep?: () => void;
  stepNames?: readonly ReplaceDocumentWizardStep[];
  onReplaced: () => void;
  clientModuleId: string;
};

export const ReplaceDocumentContext = createContext<ReplaceDocumentContext>(null!);
export const useReplaceDocumentWizard = (): ReplaceDocumentContext => useContext(ReplaceDocumentContext);
