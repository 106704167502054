import { FC, useCallback, useEffect, useMemo, useState, Suspense } from 'react';
import UploadStep from './UploadStep';
import DetailStep from './DetailStep';
import { DocumentReplaceRequest, DocumentResponse } from '../../../models/Document';
import { ReplaceDocumentContext } from '../../../contexts/ReplaceDocumentContext';
import PickTemplateStep from './PickTemplateStep';

export const replaceDocumentWizardSteps = ['upload', 'pickTemplateStep', 'detailStep'] as const;

export type ReplaceDocumentWizardStep = (typeof replaceDocumentWizardSteps)[number];

type Props = {
  document: DocumentResponse;
  onCancel: () => void;
  activeStep: ReplaceDocumentWizardStep;
  onReplaced: () => void;
};

const ReplaceDocumentWizard: FC<Props> = (props) => {
  const { onCancel, activeStep, onReplaced, document } = props;

  const steps = useMemo<Record<ReplaceDocumentWizardStep, JSX.Element>>(() => {
    return {
      upload: <UploadStep />,
      detailStep: <DetailStep />,
      pickTemplateStep: <PickTemplateStep />,
    };
  }, []);

  const [documentInternal, setDocument] = useState<DocumentReplaceRequest>({
    clientFormId: document.id,
    name: document.subtitle,
    description: document.description,
    changeSummary: '',
  });

  const [stepStack, setStepStack] = useState([steps[activeStep]]);
  const nextStep = useCallback(
    (name: ReplaceDocumentWizardStep) => {
      setStepStack((prev) => [...prev, steps[name]]);
    },
    [steps],
  );

  useEffect(() => {
    nextStep(activeStep);
  }, [activeStep, nextStep]);

  const prevStep = useCallback(() => {
    setStepStack((prev) => prev.filter((_, i) => i !== prev.length - 1));
  }, []);

  const contextValues = useMemo(
    () => ({
      classes: [],
      document: documentInternal,
      clientModuleId: document.clientModuleId,
      setDocument,
      nextStep,
      prevStep,
      cancel: onCancel,
      stepNames: replaceDocumentWizardSteps,
      onReplaced,
    }),
    [document.clientModuleId, documentInternal, nextStep, onCancel, onReplaced, prevStep],
  );

  return (
    <ReplaceDocumentContext.Provider value={contextValues}>
      <Suspense fallback={stepStack[stepStack.length - 2]}>{stepStack[stepStack.length - 1]}</Suspense>
    </ReplaceDocumentContext.Provider>
  );
};

export default ReplaceDocumentWizard;
